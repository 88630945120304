export enum StorageKeys {
  USER_EMAIL = 'USER_EMAIL',
  USER_PASSWORD = 'USER_PASSWORD',
  REMEMBER_ME = 'REMEMBER_ME',
}
export type StorageKeysType = keyof typeof StorageKeys;

export enum PageName {
  DASHBOARD = 'Dashboard',
  DATA_EXTRACTOR = 'Data Extractor',
  DUTY_DRAWBACK = 'Duty Drawback',
}

export enum NavigatePageName {
  DASHBOARD = 'DASHBOARD',
  DATA_EXTRACTOR = 'DATA_EXTRACTOR',
  DUTY_DRAWBACK = 'DUTY_DRAWBACK',
}

export enum EximProducts {
  DATA_EXTRACTOR = 'Data Extractor',
  DUTY_DRAWBACK = 'Duty Drawback',
  MOOWR = 'MOOWR',
  ADVANCE_LICENSE = 'Advance License',
  IGCRD = 'IGCRD',
}

export type PageType = keyof typeof PageName;

export const GSTIN_ALREADY_ADDED = 'is already added';

export const ERROR_PAN_MESSAGE = 'Selected GSTINs must be under same pan';

export const EXIM_HERO_STARTED_DATE = '01/07/2017';

export enum EximHeroDate {
  MIN_MONTH = '01/2018',
  MIN_DATE = '01/01/2018',
}

export const INACTIVE_TAB_ID = 'inactiveTabId';

export enum Path {
  PAGE_NOT_FOUND = '/page-not-found',
  REGISTRATION = '/registration',
  LOGIN = '/login',
  LOGOUT = '/logout',
  EMAIL_VERIFICATION = '/email-verification',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  DASHBOARD = '/dashboard',
  PROFILE = '/profile',
  THANK_YOU = '/thank-you',
  SUBSCRIPTION = '/subscription',
  DATA_EXTRACTOR = '/data-extractor',
  UPLOAD_PROCESS = '/upload-process',
  ADVANCE_EXPORT = '/advance-export',
  VIEW_UPLOAD_DETAILS = '/view-upload-details',
  DUTY_DRAWBACK = '/duty-drawback',
  EXPORT_HISTORY = '/export-history',
  VIEW_INVOICES = '/view-invoices',
  DBK_CLAIM = '/dbk-claim',
  SB = '/sb',
  SB_PRODUCT_SELECTION = '/sb-product-selection',
  SB_SUMMARY = '/sb-product-summary',
  BOM_REVIEW = '/bom-review',
  BOE = '/boe',
  BOE_ITEM_SELECTION = '/boe-item-selection',
  BOE_SUMMARY = '/boe-summary',
  CALCULATION = '/calculation',
  VIEW_DETAILS = '/view-details',
  REGISTRATION_COMPLETE = '/registration-complete',
  CLAIM_HISTORY = '/claim-history',
  CLAIM_DETAILS = '/claim-details',
  VIEW_PLAN = '/view-plan',
  SETUP_PROCESS_SELECT_ADDON = '/setup-process-select-addon',
  SETUP_PROCESS_PAYMENT_DETAILS = '/setup-process-payment-details',
  USER_PROFILE = '/user-profile',
  COMPANY_PROFILE = '/company-profile',
  UNIFIED_USER_MANAGEMENT = '/user-management',
  NOTIFICATIONS_SETTINGS = '/notification-settings',
  MANAGE_SUBSCRIPTION = '/manage-subscriptions',
  FEEDBACK_CONTACT_US = '/feedback-contact-us',
  CLAIM_REPORTS = '/claim-reports',
  DATA_PARSING_SETUP = '/data-parsing-setup',
  ADD_USER = '/add-user',
  MOOWR = '/moowr',
  TRANSACTION = '/transaction',
  PROD_SELECTION = '/product-selection',
  PRODUCT_SUMMARY = '/product-summary',
  INWARD_REVIEW = '/inward-review',
  CONSUMPTION_CAL = '/consumption-report',
  ITEM_SELECTION = '/item-selection',
  WASTAGE_SELECTION = '/wastage-selection',
}

// Profile page tabs id
export enum ProfilePages {
  USER_PROFILE = 'user-profile',
  COMPANY_PROFILE = 'company-profile',
  UNIFIED_USER_MANAGEMENT = 'user-management',
  NOTIFICATIONS_SETTINGS = 'notification-settings',
  MANAGE_SUBSCRIPTION = 'manage-subscriptions',
  FEEDBACK_CONTACT_US = 'feedback-contact-us',
  CLAIM_REPORTS = 'claim-reports',
  DATA_PARSING_SETUP = 'data-parsing-setup',
}

// Profile page tabs name
export enum ProfileTabs {
  USER_PROFILE = 'User Profile',
  COMPANY_PROFILE = 'Company Profile',
  UNIFIED_USER_MANAGEMENT = 'Unified User Management',
  NOTIFICATIONS_SETTINGS = 'Notifications Settings',
  MANAGE_SUBSCRIPTION = 'Manage Subscriptions',
  FEEDBACK_CONTACT_US = 'Feedback / Contact Us',
  CLAIM_REPORTS = 'Claim Reports Settings',
  DATA_PARSING_SETUP = 'Data Parsing Setup',
}

export enum Api {
  EXIM_SUBSCRIPTION_SERVICE = '/eximhero-subscription-service',
  USERS = '/users',
  LOGIN = '/login',
  LOGOUT = '/logout',
  SIGNUP = '/signup',
  USER_DETAILS = '/user-details',
  USERS_SINGLE = '/users-single',
  ORGANIZATIONS = '/organizations',
  MANAGEMENT = '/management',
  INVITED = '/invited',
  RESEND_INVITE = '/resend-invite',
  VERIFY = '/verify',
  BUSINESS = '/business',
  COMPANY = '/company',
  PROFILE = '/profile',
  ALL = '/all',
  FORGOT_PASSWORD = '/forgot-password',
  RESEND_VERIFY_EMAIL_LINK = '/resend-verify-email-link',
  VERIFY_EMAIL = '/verify-email',
  VERIFY_REQUEST = '/verify-request',
  RESET_PASSWORD = '/reset-password',
  PDF = '/pdf',
  PDF_READER = '/pdf-reader',
  FILES = '/files',
  UPLOAD = '/upload',
  HISTORY = '/history',
  PROCESSING_DETAILS = '/processing-details',
  DETAILS = '/details',
  REPORTS = '/reports',
  GENERATE = '/generate',
  EXPORT = '/export',
  EDIT_REMARK = '/edit-remark',
  PROCESSED_FILES = '/processed-files',
  EXIM = '/exim',
  DBK = '/dbk',
  SIGNATORY_DETAILS = '/signatory-details',
  FIELD_IDENTIFICATION_STRATEGY = '/field-identification-strategy',
  INVOICES = '/invoices',
  EDIT = '/edit',
  DOWNLOAD_TEMPLATE = '/download-template',
  FILE_UPLOAD = '/file-upload',
  UPLOAD_FILES = '/upload-files',
  FILE_PROCESSING_DETAILS = '/file-processing-details',
  DBK_CLAIM = '/dbk-claim',
  EXIM_DBK_PROCESS_STATUS = '/exim-dbk-process-status',
  SB_LIST = '/sb-list',
  SUMMARY = '/summary',
  EXPORT_REPORT = '/export-report',
  SB_DETAILS = '/sb-details',
  BOE_DETAILS = '/boe-details',
  BOM_LIST = '/bom-list',
  SAVE_BOM_REVIEW = '/save-bom-review',
  BOM_SAVE = '/bom-save',
  BOM_DETAILS = '/bom-details',
  BOE_LIST = '/boe-list',
  BOE_SAVE = '/boe-save',
  ITEM_LIST = '/item-list',
  ITEM_SAVE = '/item-save',
  SB_SAVE = '/sb-save',
  PRODUCT_LIST = '/product-list',
  PRODUCT_SAVE = '/product-save',
  SAVE_SB_PROD = '/sb-prod-save',
  DISCARD = '/discard',
  GSTIN_DETAILS = '/gsthero/gstin/subscription-authorization',
  BOE_ITEM_SAVE = '/boe-item-save',
  DBK_CLAIM_DETAILS = '/dbk-claim-details',
  DBK_CALCULATION = '/dbk-calculation',
  CALCULATION_SUMMARY = '/summary',
  PROCESS_DBK = '/process-dbk',
  DBK_CLAIM_STATUS = '/dbk-claim-status',
  SUPPORTED_DOC = '/supported-doc',
  DOWNLOAD = '/download',
  DBK_SUMMARY = '/dbk-summary',
  FREEZ_CLAIM = '/freez-claim',
  AUTO_RECOMPUTE = '/auto-recompute',
  VERIFY_PARTNER_API = '/partner/verify',
  SUBSCRIPTIONS = '/subscriptions',
  RENEW = '/renew',
  SAVE_LATER = '/save-later',
  ACTIVE_GSTIN = '/active-gstin',
  PLANS = '/plans',
  ADDONS = '/addons',
  MOOWR_SERVICE = '/moowr-service',
  MOOWR_FILING = '/moowr-filing',
  FILE_UPLOAD_TEMPLATE = '/file-upload-templates',
  TXN = '/txn',
  DTLS = '/dtls',
  OUTWARD_STOCK_DETAILS = '/outward-stock-details',
  OUTWARD_DETAILS = '/outward-details',
  JOB_WORK_DETAILS = '/job-work-details',
  PARTIAL_OUTWARD_RECORDS = '/partial-outward-records',
  PARTIAL_OUTWARD_RECORDS_REVIEW = '/partial-outward-records-review',
  SELECTION_SUMMARY = '/selection-summary',
  INWARD_LIST = '/inward-list',
  INWARD_ITEM_LIST = '/inward-item-list',
  SAVE_INWARD_REVIEW = '/save-inward-review',
  PROCESS_CONSUMPTION = '/process-consumption',
  CONSUMPTION_SUMMARY = '/consumption-summary',
  FREEZE = '/freeze',
}

export enum ApiVersion {
  V1 = '/v1',
  V2 = '/v2',
}

export enum HelmetTitle {
  ID = 'Id',
  DASHBOARD = 'DASHBOARD',
  DATA_EXTRACTOR = 'Data Extractor',
  DUTY_DRAWBACK = 'Duty Drawback',
  MOOWR = 'MOOWR',
  CLAIM_HISTORY = 'Claim History',
  UPLOAD_AND_PROCESS = 'Upload and Process',
  VIEW_UPLOAD_DETAILS = 'View Upload Details',
  CLAIM_DETAILS = 'Claim Details',
}

export enum ApiAction {
  LOGOUT = 'LOGOUT',
  GET = 'GET',
  GET_SUB = 'GET_SUB',
  MANAGE_ORG = 'MANAGE_ORG',
  GET_USER = 'GET_USER',
  ADD_USER = 'ADD_USER',
  MANAGE_USER = 'MANAGE_USER',
  FILE_UPLOAD_HISTORY = 'FILE_UPLOAD_HISTORY',
  FILE_PROCESSING_DTLS = 'FILE_PROCESSING_DTLS',
  PDF_FILE_UPLOAD = 'PDF_FILE_UPLOAD',
  GET_PDF_FILE = 'GET_PDF_FILE',
  FILE_UPLOAD_DETAILS = 'FILE_UPLOAD_DETAILS',
  DELETE_PDF_FILE = 'DELETE_PDF_FILE',
  EXPORT_REPORT = 'EXPORT_REPORT',
  ADVANCE_EXPORT = 'ADVANCE_EXPORT',
  GET_PROCESSED_FILES = 'GET_PROCESSED_FILES',
  EXIM_DOWNLOAD_TEMPLATE = 'EXIM_DOWNLOAD_TEMPLATE',
  EXIM_FILE_UPLOAD = 'FILE_UPLOAD',
  EXIM_FILE_UPLOAD_HISTORY = 'EXIM_FILE_UPLOAD_HISTORY',
  EXIM_DOWNLOAD_EXCEL_TEMPLATE = 'EXIM_DOWNLOAD_EXCEL_TEMPLATE',
  EXIM_DBK_PROCESS_STATUS = 'EXIM_DBK_PROCESS_STATUS',
  EXIM_GET_SB_LIST = 'EXIM_GET_SB_LIST',
  EXIM_GET_INVOICES = 'EXIM_GET_INVOICES',
  EXIM_GET_INVOICES_SUMMARY = 'EXIM_GET_INVOICES_SUMMARY',
  GET_FILE_EXPORT_HISTORY = 'GET_FILE_EXPORT_HISTORY',
  EXIM_GET_SB_DETAILS = 'EXIM_GET_SB_DETAILS',
  EXIM_GET_BOM_LIST = 'EXIM_GET_BOM_LIST',
  EXIM_SAVE_BOM_REVIEW = 'EXIM_SAVE_BOM_REVIEW',
  EXIM_GET_BOM_DETAILS = 'EXIM_GET_BOM_DETAILS',
  EXIM_GET_BOE_LIST = 'EXIM_GET_BOE_LIST',
  EXIM_SAVE_BOE_SELECTION = 'EXIM_SAVE_BOE_SELECTION',
  EXIM_GET_ITEM_LIST = 'EXIM_GET_ITEM_LIST',
  EXIM_SAVE_BOE_ITEM_SELECTION = 'EXIM_SAVE_BOE_ITEM_SELECTION',
  EXIM_SAVE_SB_SELECTION = 'EXIM_SAVE_SB_SELECTION',
  EXIM_GET_SB_PRODUCT_LIST = 'EXIM_GET_SB_PRODUCT_LIST',
  EXIM_SAVE_SB_PRODUCT_SELECTION = 'EXIM_SAVE_SB_PRODUCT_SELECTION',
  EXIM_GET_DBK_CLAIM_SMRY = 'EXIM_GET_DBK_CLAIM_SMRY',
  EXIM_SAVE_SB_PROD_SMRY = 'EXIM_SAVE_SB_PROD_SMRY',
  EXIM_GET_BOE_DETAILS = 'EXIM_GET_BOE_DETAILS',
  EXIM_EDIT_INVOICES = 'EXIM_EDIT_INVOICES',
  EXIM_DBK_CLAIM_HISTORY = 'EXIM_DBK_CLAIM_HISTORY',
  DISCARD_DBK_CLAIM = 'DISCARD_DBK_CLAIM',
  EXIM_DELETE_INVOICES = 'EXIM_DELETE_INVOICES',
  DISCARD_TXN = 'DISCARD_TXN',
  EXIM_SAVE_BOE_ITEM_SMRY = 'EXIM_SAVE_BOE_ITEM_SMRY',
  EXIM_GET_DBK_CAL_SMRY = 'EXIM_GET_DBK_CAL_SMRY',
  EXIM_PROCESS_DBK_CLAIM = 'EXIM_PROCESS_DBK_CLAIM',
  EXIM_DBK_CLAIM_DETAILS = 'EXIM_DBK_CLAIM_DETAILS',
  EXIM_UPDATE_DBK_CLAIM_STATUS = 'EXIM_UPDATE_DBK_CLAIM_STATUS',
  EXIM_UPLOAD_DBK_CLAIM_SUPP_DOCS = 'EXIM_UPLOAD_DBK_CLAIM_SUPP_DOCS',
  EXIM_DOWNLOAD_DBK_CLAIM_SUPP_DOCS = 'EXIM_DOWNLOAD_DBK_CLAIM_SUPP_DOCS',
  EXIM_GET_DBK_SMRY = 'EXIM_GET_DBK_SMRY',
  EXIM_FREEZ_CLAIM = 'EXIM_FREEZ_CLAIM',
  EXIM_AUTO_RECOMPUTE = 'EXIM_AUTO_RECOMPUTE',
  GET_PLANS = 'GET_PLANS',
  GET_GSTIN_DETAILS = 'GET_GSTIN_DETAILS',
  GET_ADDONS = 'GET_ADDONS',
  VERIFY_PARTNER = 'VERIFY_PARTNER',
  CREATE_SUB = 'CREATE_SUB',
  RENEW_SUB = 'RENEW_SUB',
  ACTIVE_GSTIN = 'ACTIVE_GSTIN',
  GET_SIGNATORY_DETAILS = 'GET_SIGNATORY_DETAILS',
  MANAGE_SIGNATORY_DETAILS = 'MANAGE_SIGNATORY_DETAILS',
  GET_FIELD_IDENTIFICATION_STRATEGY = 'GET_FIELD_IDENTIFICATION_STRATEGY',
  SET_FIELD_IDENTIFICATION_STRATEGY = 'SET_FIELD_IDENTIFICATION_STRATEGY',
  DELETE_FIELD_IDENTIFICATION_STRATEGY = 'DELETE_FIELD_IDENTIFICATION_STRATEGY',
  CREATE_MOOWR_RETURN_TXN = 'CREATE_MOOWR_RETURN_TXN',
  EDIT_MOOWR_RETURN_TXN = 'EDIT_MOOWR_RETURN_TXN',
  GET_MOOWR_RETURN_TXN_DTLS = 'GET_MOOWR_RETURN_TXN_DTLS',
  DISCARD_MOOWR_RETURN_TXN = 'DISCARD_MOOWR_RETURN_TXN',
  GET_MOOWR_RETURN_TXN_HISTORY = 'GET_MOOWR_RETURN_TXN_HISTORY',
  GET_PARTIAL_OUTWARD_RECORDS = 'GET_PARTIAL_OUTWARD_RECORDS',
  SAVE_PARTIAL_OUTWARD_SELECTION = 'SAVE_PARTIAL_OUTWARD_SELECTION',
  GET_MOOWR_RETURN_TXN_SELECT_SUM = 'GET_MOOWR_RETURN_TXN_SELECT_SUM',
  SAVE_PARTIAL_OUTWARD_SELECTION_REVIEW = 'SAVE_PARTIAL_OUTWARD_SELECTION_REVIEW',
  SAVE_BOM_REVIEW = 'SAVE_BOM_REVIEW',
  GET_EXPORT_HISTORY = 'GET_EXPORT_HISTORY',
  GET_TXN_STATUS = 'GET_TXN_STATUS',
  DOWNLOAD_REPORT = 'DOWNLOAD_REPORT',
  DOWNLOAD_FILE_TEMPLATE = 'DOWNLOAD_FILE_TEMPLATE',
  FILE_UPLOAD = 'FILE_UPLOAD',
  GET_INWARD_RECORD_LIST = 'GET_INWARD_RECORD_LIST',
  GET_INWARD_RECORD_ITEM_LIST = 'GET_INWARD_RECORD_ITEM_LIST',
  SAVE_INWARD_REVIEW = 'SAVE_INWARD_REVIEW',
  PROCESS_CONSUMPTION = 'PROCESS_CONSUMPTION',
  CONSUMPTION_SUMMARY = 'CONSUMPTION_SUMMARY',
  FREEZE_TXN = 'FREEZE_TXN',
  MOOWR_GET_BOM_DETAILS = 'MOOWR_GET_BOM_DETAILS',
  MOOWR_GET_INVOICES = 'MOOWR_GET_INVOICES',
  MOOWR_GET_INVOICES_SUMMARY = 'MOOWR_GET_INVOICES_SUMMARY',
  DISCARD_INVALID_FILE_TXN = 'DISCARD_INVALID_FILE_TXN',
  MOOWR_GET_OUTWARD_STOCK_DETAILS = 'MOOWR_GET_OUTWRD_STOCK_DETAILS',
  MOOWR_GET_OUTWARD_RECORD_DETAILS = 'MOOWR_GET_OUTWARD_RECORD_DETAILS',
  MOOWR_GET_JOB_WORK_DETAILS = 'MOOWR_GET_JOB_WORK_DETAILS',
  EDIT_INVOICES = 'EDIT_INVOICES',
}

export enum SummaryCardType {
  TOTAL_OUTWARD_SUPPLY = 'Total Outward Supply',
  TOTAL_INWARD_SUPPLY = 'Total Inward Supply',
}

export enum SubscriptionStatus {
  FREE = 'FREE',
  PAID = 'PAID',
  EXPIRED = 'EXPIRED',
  UNREGISTERED = 'UNREGISTERED',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
}

export enum PlanType {
  FREE = 'free',
  ADVANCE = 'advance',
  STANDARD = 'standard',
  BASIC = 'basic',
}

export enum PlanCode {
  ADVANCE = 'STD',
  STANDARD = 'STD',
  BASIC = 'BASIC',
}

export enum PlanName {
  BASIC = 'Basic Plan',
  ADVANCE = 'Advance Plan',
}

export enum ResponseStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}
export enum AlertStatus {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
}

export enum FilingSteps {
  'Discarded',
  'Completed',
  'Draft Generated',
  'Reconciled',
}
export type FilingType = keyof typeof FilingSteps;

export enum SupportContact {
  mail = 'support@eximhero.com',
  phone = '+91 800 7700 800',
}

export const USER_ROLE = {
  MASTER_ADMIN: 'Master Admin',
  ADMIN: 'Admin',
  BASIC: 'Basic',
};

/** In order to hide the header on that specific route page, pass the routes in the following hideHeaderPaths array.
 * Path.SUBSCRIPTION + Path.THANK_YOU = /subscription/thank-you (this page does not require a header)
 */
export const HIDE_HEADER_PATHS = [Path.SUBSCRIPTION + Path.THANK_YOU];

/*
NOTE: In validateGstin, the 11th character can only be the letter "Z",
while in validateGstin2, the 11th character can be the letter "Z",
any digit from 1 to 9, or any uppercase letter from A to J.
*/
/** Info: please add the REGEXP expression here only */
export const REGEXP = {
  whiteSpace: /\s/,
  passwordSpace: /^\S*$/,
  userInitials: /\b\w/g,
  formatNumberWithComma: /\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g,
  validateGstinWithFixedZ:
    /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}$/,
  validateGstinWithFlexibleCharacter:
    /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1}$/,
  validateGstin:
    /^(?:[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Zz1-9A-Ja-j]{1}[0-9a-zA-Z]{1}|[0-9]{4}[A-Z]{3}[0-9]{5}[UO]{1}[N][A-Z0-9]{1}|[0-9]{2}[a-zA-Z]{4}[0-9]{5}[a-zA-Z]{1}[0-9]{1}[Z]{1}[0-9]{1}|[0-9]{4}[a-zA-Z]{3}[0-9]{5}[N][R][0-9a-zA-Z]{1}|[0-9]{2}[a-zA-Z]{4}[a-zA-Z0-9]{1}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[D]{1}[0-9a-zA-Z]{1}|[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[C]{1}[0-9a-zA-Z]{1}|[9][9][0-9]{2}[a-zA-Z]{3}[0-9]{5}[O][S][0-9a-zA-Z]{1})$/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,64}$/,
  mobileNumber: /^([+]\d{2})?[6-9]\d{9}$/,
  numbers: /^(?!0\d*$)\d+$/,
  numbersWithDecimal: /^(?!0\d*$)\d+(\.\d{0,2})?$/,
  name: /^([a-zA-Z ]){1,100}$/,
  strings: /^[a-zA-Z]+$/,
  alphanumericValue: /^[a-zA-Z0-9]{10}$/,
  alphanumeric: /^[a-zA-Z0-9 ]*$/,
  partnerCode: /^(R|P|C)(GSTH|SM)(\d)+$/,
  validatePan: /^[A-Z]{5}\d{4}[A-Z]$/,
  digits: /^[^0-9]*$/,
  numbersWithZero: /^[0-9]*$/,
  extraSpaces: /^(?!.*\s{2,}).*$/,
  specialChar: /^[^a-zA-Z0-9]*$/,
  gstInvoiceNo:
    /^(?=.{1,16}$)([/\-0]*[a-zA-Z0-9/\\-]*[a-zA-Z1-9]+[a-zA-Z0-9/\\-]*)$/,
  time: /^(?:[01]\d|2[0-3]):[0-5]\d(?::[0-5]\d)?$/,
};

export const DATA_EXTRACTOR_FILE_TYPE = {
  SB: 'SHIPPING_BILL',
  BOE: 'BILL_OF_ENTRY',
  ALL: 'ALL',
};
export type FileType = keyof typeof DATA_EXTRACTOR_FILE_TYPE;

export enum UploadLogsStatus {
  IN_PROGRESS = 'in-progress',
  FAILED = 'failed',
  COMPLETED = 'completed',
  DELETED = 'deleted',
}

export enum SupportedFileTypes {
  ZIP = 'application/zip',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  JPG = 'image/jpg',
  PDF = 'application/pdf',
  RAR = 'application/vnd.rar',
  PLAIN_TEXT = 'text/plain',
  ANY = '*/*',
}

//  =================== DUTY DRAWBACK ====================
export const DUTY_DRAWBACK_FILE_TYPE = {
  SB: 'EXPORT_FILE',
  BOE: 'IMPORT_FILE',
  BOM: 'BOM_FILE',
  DBK: 'CUSTOM_DBK_RATE_FILE',
  ALL: 'ALL',
};
export type DDFileType = keyof typeof DUTY_DRAWBACK_FILE_TYPE;

export const DUTY_DRAWBACK_ROUTE_TYPE = {
  EXPORT_FILE: 'SB',
  IMPORT_FILE: 'BOE',
  BOM_FILE: 'BOM',
  CUSTOM_DBK_RATE_FILE: 'DBK',
};
export type DDRouteType = keyof typeof DUTY_DRAWBACK_ROUTE_TYPE;

export enum UploadProcessTitle {
  EXPORT_FILE = 'Export Statement',
  LOCAL_SALES = 'Local Sales (Optional)',
  IMPORT_FILE = 'Import / Domestic Purchase Statement',
  BOM_FILE = 'Bill of Materials (BOM)',
  CUSTOM_DBK_RATE_FILE = `Custom's DBK Rate (Optional)`,
}
export type DDUploadProcessTitle = keyof typeof UploadProcessTitle;

export enum UploadProcessNavTitle {
  SB = 'Export / Domestic Sale Statement',
  BOE = 'Import / Domestic Purchase Statement',
  BOM = 'Bill of Materials (BOM)',
  DBK = `Custom's DBK Rate`,
}
export type DDUploadProcessNavTitle = keyof typeof UploadProcessNavTitle;

export enum DbkClaimSteps {
  STEP_1_1 = 'sb',
  STEP_1_2 = 'sb-product-selection',
  STEP_1_3 = 'sb-product-summary',
  STEP_2 = 'bom-review',
  STEP_3_1 = 'boe',
  STEP_3_2 = 'boe-item-selection',
  STEP_3 = 'boe-summary',
  STEP_4 = 'calculation',
}
export type ClaimStepType = typeof DbkClaimSteps;

export const DBK_CLAIM_REPORT_TYPE = {
  SB_SELECTION_REPORT: 'SB_SELECTION_REPORT',
  BOE_SELECTION_REPORT: 'BOE_SELECTION_REPORT',
  BOM_SELECTION_REPORT: 'BOM_SELECTION_REPORT',
};

export const APPLICANT_TYPE = [
  {key: 'INTERMEDIARY_PARTY', value: 'Intermediary'},
  {key: 'EXPORT_PARTY', value: 'Exporter'},
];

export enum applicantTypes {
  INTERMEDIARY = 'Intermediary',
  EXPORTER = 'Exporter',
}

export enum EximSchemesType {
  SB_PROD_SELECT = 'SB_PROD_SELECT',
  BOE_ITEM_SELECT = 'BOE_ITEM_SELECT',
  BOM_REVIEW_TYPE = 'BOM_REVIEW',
  DBK_CAL = 'DBK_CAL',
  DBK_CLAIM_HISTORY = 'DBK_CLAIM',
}

export const SB_PROD_SUMMARY = {
  SB_LIST: 'SB_LIST',
  PROD_LIST: 'PROD_LIST',
};

// subscription plan types
export const PLAN_TYPE = {
  FREE: 'Free',
  BASIC: 'BASIC',
  ADVANCE: 'ADVANCE',
  PREMIUM: 'PREMIUM',
};

// User Management
export const UNIFIED_ACCESS_TYPE = {
  ALL_BUSINESS: 'ALL_BUSINESS',
  BUSINESS_WISE: 'BUSINESS_WISE',
  PRODUCT_WISE: 'PRODUCT_WISE',
};

export const MOOWR_FILE_ROUTE_TYPE = {
  PARTIAL_OUTWARD_REGISTER: 'PARTIAL_OUTWARD_REGISTER',
  INWARD_REGISTER: 'INWARD_REGISTER',
  BOM_STATEMENT: 'BOM_STATEMENT',
  OUTWARD_REGISTER: 'OUTWARD_REGISTER',
  JOB_WORK_REGISTER: 'JOB_WORK_REGISTER',
};

export const MOOWR_ROUTE_TYPE = {
  PARTIAL_OUTWARD_REGISTER: '/outward-stock',
  INWARD_REGISTER: '/inward-register',
  BOM_STATEMENT: '/bom-statement',
  OUTWARD_REGISTER: '/outward-register',
  JOB_WORK_REGISTER: '/job-work-register',
};
export type MoowrRouteType = keyof typeof MOOWR_ROUTE_TYPE;

export enum MoowrUploadProcessTitle {
  PARTIAL_OUTWARD_REGISTER = 'Outward Stock',
  INWARD_REGISTER = 'Inward Register',
  BOM_STATEMENT = 'Bill of Material(BOM)',
  OUTWARD_REGISTER = 'Outward Register',
  JOB_WORK_REGISTER = 'Job Work',
}
export type MoowrUploadProcessTitleType = keyof typeof MoowrUploadProcessTitle;

export enum MoowrSubTransactionType {
  PROD_SELECTION = 'PROD_SELECTION',
  BOM_REVIEW = 'BOM_REVIEW',
  INWARD_REVIEW = 'INWARD_REVIEW',
  CONSUMPTION_CAL = 'CONSUMPTION_CAL',
  ITEM_SELECTION = 'ITEM_SELECTION',
  WASTAGE_SELECTION = 'WASTAGE_SELECTION',
}

export enum MoowrSubTransactionTypeUIText {
  PROD_SELECTION = 'Product Selection',
  BOM_REVIEW = 'BOM Review',
  INWARD_REVIEW = 'Inward Review',
  CONSUMPTION_CAL = 'Consumption Report Review',
  ITEM_SELECTION = 'Item Selection',
  WASTAGE_SELECTION = 'Wastage Selection',
}

export enum MoowrTransactionStatus {
  PROD_SELECT_IP = 'PROD_SELECT_IP',
  PROD_SELECT_REVIEW_IP = 'PROD_SELECT_REVIEW_IP',
  PROD_SELECT_CMPLT = 'PROD_SELECT_CMPLT',
  PROD_SELECT_FAILED = 'PROD_SELECT_FAILED',
  FREEZED = 'FREEZED',
  DISCARDED = 'DISCARDED',
  BOM_REVIEW_IP = 'BOM_REVIEW_IP',
  BOM_REVIEW_CMPLT = 'BOM_REVIEW_CMPLT',
  BOM_REVIEW_FAILED = 'BOM_REVIEW_FAILED',
  INWARD_REVIEW_IP = 'INWARD_REVIEW_IP',
  INWARD_REVIEW_CMPLT = 'INWARD_REVIEW_CMPLT',
  INWARD_REVIEW_FAILED = 'INWARD_REVIEW_FAILED',
  CONSUMPTION_CAL_REVIEW_IP = 'CONSUMPTION_CAL_REVIEW_IP',
  CONSUMPTION_READY_TO_CAL = 'CONSUMPTION_READY_TO_CAL',
  CONSUMPTION_CAL_IP = 'CONSUMPTION_CAL_IP',
  CONSUMPTION_CAL_CMPLT = 'CONSUMPTION_CAL_CMPLT',
  CONSUMPTION_CAL_FAILED = 'CONSUMPTION_CAL_FAILED',
  ITEM_SELECT_IP = 'ITEM_SELECT_IP',
  ITEM_SELECT_REVIEW_IP = 'ITEM_SELECT_REVIEW_IP',
  ITEM_SELECT_CMPLT = 'ITEM_SELECT_CMPLT',
  ITEM_SELECT_FAILED = 'ITEM_SELECT_FAILED',
  WASTE_SELECT_IP = 'WASTE_SELECT_IP',
  WASTE_SELECT_REVIEW_IP = 'WASTE_SELECT_REVIEW_IP',
  WASTE_SELECT_CMPLT = 'WASTE_SELECT_CMPLT',
  WASTE_SELECT_FAILED = 'WASTE_SELECT_FAILED',
}

export const MOOWR_TRANSACTION_TYPE = {
  MOOWR_FG_TXN: 'Finished Goods Sales',
  MOOWR_RETURN_TXN: 'Return / Trade',
  MOOWR_WASTAGE_TXN: 'Wastage',
};
export type MoowrTxnType = keyof typeof MOOWR_TRANSACTION_TYPE;

export enum MoowrTransactionSteps {
  STEP_1_1 = 'product-selection',
  STEP_1_2 = 'product-summary',
  STEP_2 = 'bom-review',
  STEP_3 = 'inward-review',
  STEP_4 = 'consumption-report',
}
export type MoowrTxnStepType = typeof MoowrTransactionSteps;

export const MOOWR_TRANSACTION_REPORT_TYPE = {
  SELECTED_PARTIAL_OUTWARD_RECORDS: 'SELECTED_PARTIAL_OUTWARD_RECORDS',
  SELECTED_PARTIAL_OUTWARD_REGISTER: 'SELECTED_PARTIAL_OUTWARD_REGISTER',
  SELECTED_BOM_STATEMENT: 'SELECTED_BOM_STATEMENT',
  SELECTED_BOE_RECORDS: 'SELECTED_BOE_RECORDS',
  MOOWR_TXN_REPORT: 'MOOWR_TXN_REPORT',
  MOOWR_TXN_CAL_SUMM_REPORT: 'MOOWR_TXN_CAL_SUMM_REPORT',
};

export const MOOWR_SALE_TYPE = {
  EXPORT_MFG: 'Export (Mfg)',
  DOMESTIC_MFG: 'Domestic (Mfg)',
  EXPORT_TRAD: 'Export (trading)',
  DOMESTIC_TRAD: 'Domestic (trading)',
};
